
import { defineComponent, reactive } from 'vue'
import axios from 'axios'

export default defineComponent({
    setup() {
        const disabled = reactive({
            value: true
        })

        const open_modal = reactive({
            state: false
        })

        const email = reactive({
            id: 'email',
            type: 'text',
            value: '',
            required: true,
            label: 'Email *',
            pattern: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/i,
            valid: false,
            error: ''
        }) as any
        
        const validForm = (field: any) => {
            if(field.value) {
                if(field.id === 'email' && !field.value.match(/@/gi)) {
                    field.error = 'Поле может содержать только латиницу, цифры и специальные символы'
                } else {
                    field.error = ""
                }
               
            } else if(!field.value.length && field.required) {   
                field.error = 'Поле обязательно к заполнению'
            } 
        }

        const setValue = (val: any) => {
            email.value = val.value
        }

        return {
            disabled,
            open_modal,
            email,
            validForm,
            setValue
        }
    },
    data() {
        return {
            file: null as any,

        }
    },
    methods: {
        uploadFileApplication() {
            const file_input = this.$refs.fileInputApplication as any
            console.log(this.file);
            
            file_input.click()
            file_input.addEventListener('change', () => {
                this.file = file_input.files[0]
                if(this.file && this.email.value.length) {
                    this.disabled.value = false
                }
            })
        },
        removeFile() {
            this.file = null
            this.disabled.value = true
        },
        sendApplication() {     
            if(this.file && this.email.value.length && !this.email.error.length) {
                const fd = new FormData 
                fd.append('file', this.file)
                fd.append('email', this.email.value)
                axios
                    .post("solution", fd)
                    .then(() => {this.open_modal.state = true})
                    .catch(err => {this.email.error = err.response.data})
            }
        }
    }

})
