
import { defineComponent } from 'vue'

export default defineComponent({
    setup() {
        const redirectToLink = (href: string) => {
            window.location.href = href
        }

        return {
            redirectToLink
        }
    }
})

