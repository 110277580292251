
import { defineComponent, reactive, watch } from 'vue'

export default defineComponent({
    props: {
        input_type: {
            type: String,
            default: 'text'
        },
        input_value: {
            type: String || Function,
            default: ''
        },
        input_label: {
            type: String,
            default: 'label'
        },
        input_error: {
            type: String,
            default: ''
        },
        input_mask: {
            type: String,
            default: ''
        },
        input_selector: {
            type: Boolean,
            default: false
        }
    },
    setup(props) {
        const input_field_value = reactive({
            value: props.input_value
        })

        watch(() => props.input_value, (new_value) => {
            input_field_value.value = new_value
        })

        return {
            input_field_value
        }
    },
    methods: {
        setValue() {
            this.$emit('set', {value: this.input_field_value.value, type: this.$props.input_type})
        },
    }
})
