
import { defineComponent, onMounted } from 'vue';
import onScrollAnimate from '../../unilities/scrollAnimate'

export default defineComponent({
    setup() {
        const text_content = [
            {
                paragraph: '<h3>Общие положения</h3>',
                text: [
                    'ООО «ОЙЛКЕЙС» (далее — «Оператор») ставит соблюдение прав и свобод граждан одним из важнейших условий осуществления своей деятельности.',
                    'Политика Оператора в отношении обработки персональных данных (далее — «Политика») применяется ко всей информации, которую Оператор может получить о посетителях веб-сайта <a href="/">oilcase.ru</a>. Персональные данные обрабатывается в соответствии с ФЗ «О персональных данных» № 152-ФЗ.'
                ]
            },
            {
                paragraph: '<h3>Основные понятия, используемые в Политике</h3>',
                text: [
                    'Веб-сайт — совокупность графических и информационных материалов, а также программ для ЭВМ и баз данных, обеспечивающих их доступность в сети интернет по сетевому адресу <a href="/">oilcase.ru.</a>',
                    'Пользователь — любой посетитель веб-сайта <a href="/">oilcase.ru.</a>',
                    'Персональные данные — любая информация, относящаяся к Пользователю веб-сайта <a href="/">oilcase.ru.</a>',
                    'Обработка персональных данных — любое действие с персональными данными, совершаемые с использованием ЭВМ, равно как и без их использования.',
                    'Обезличивание персональных данных — действия, результатом которых является невозможность без использования дополнительной информации определить принадлежность персональных данных конкретному Пользователю или лицу.',
                    'Распространение персональных данных — любые действия, результатом которых является раскрытие персональных данных неопределенному кругу лиц.',
                    'Предоставление персональных данных — любые действия, результатом которых является раскрытие персональных данных определенному кругу лиц.',
                    'Уничтожение персональных данных — любые действия, результатом которых является безвозвратное уничтожение персональных на ЭВМ или любых других носителях.'
                ]
            },
            {
                paragraph: '<h3>Оператор может обрабатывать следующие персональные данные</h3>',
                text: [
                    'Фамилия, имя, отчество; пол; ИНН; СНИЛС; адрес электронной почты; веб-адрес личного профиля в социальных сетях; номера телефонов; год, месяц, дата и место рождения; фотографии; реквизиты документа, удостоверяющего личность; адрес фактического места проживания и регистрации по месту жительства и/или по месту пребывания; сведения об образовании, профессии, специальности и квалификации, реквизиты документов об образовании.',
                    'На веб-сайте oilcase.ru происходит сбор и обработка обезличенных данных о посетителях, в том числе файлов «cookie», с помощью сервисов интернет-статистики (Яндекс.Метрика и других). К обезличенным данным о пользователях относятся технические характеристики устройства, IP-адрес, сведения о местоположении, информация об используемом браузере и языке, дата и время доступа к веб-сайту, адреса запрашиваемых страниц и иная подобная информация.'
                ]
            },
            {
                paragraph: '<h3>Цели обработки персональных данных</h3>',
                text: [
                    'Цель обработки персональных данных Пользователя — осуществление деятельности Оператора в соответствии с заявленными ОКВЭД.',
                    'Оператор имеет право направлять Пользователю уведомления о новых продуктах и услугах, специальных предложениях и различных событиях. Пользователь всегда может отказаться от получения информационных сообщений, направив Оператору письмо на адрес электронной почты <a href="mailto:info@oilcase.ru">info@oilcase.ru.</a>',
                    'Обезличенные данные Пользователя , собираемые с помощью сервисов интернет-статистики, служат для сбора информации о действиях Пользователя на веб-сайте, улучшения качества веб-сайта и его содержания.'
                ]
            },
            {
                paragraph: '<h3>Правовые основания обработки персональных данных</h3>',
                text: [
                    'Оператор обрабатывает персональные данные Пользователя только в случае их отправки Пользователем через формы, расположенные на веб-сайте <a href="/">oilcase.ru.</a> Отправляя свои персональные данные Оператору, Пользователь выражает свое согласие с данной Политикой.',
                    'Оператор обрабатывает обезличенные данные о Пользователе в случае, если это разрешено в настройках браузера Пользователя (включено сохранение файлов «cookie» и использование технологии JavaScript).',
                ]
            },
            {
                paragraph: '<h3>Порядок сбора, хранения, передачи и других видов обработки персональных данных</h3>',
                text: [
                    'Оператор обеспечивает сохранность персональных данных и принимает все возможные меры, исключающие доступ к персональным данным неуполномоченных лиц.',
                    'Персональные данные Пользователя никогда, ни при каких условиях не будут переданы третьим лицам, за исключением случаев, связанных с осуществлением деятельности Оператора и исполнением действующего законодательства.',
                    'Пользователь соглашается на использование и обработку его персональной информации системами аналитики Яндекс.Метрика и Google Analytics. Персональная информация, обрабатываемая сервисом Google Analytics, может обрабатываться за пределами Российской Федерации. Пользователь уведомлен об этом и дает свое согласие.',
                    'В случае выявления неточностей в персональных данных, Пользователь может актуализировать их, направив Оператору письмо на адрес электронной почты <a href="mailto:info@oilcase.ru">info@oilcase.ru.</a> с пометкой «Актуализация персональных данных».',
                    'Срок обработки персональных данных является неограниченным. Пользователь может в любой момент отозвать свое согласие на обработку персональных данных, направив Оператору письмо на адрес электронной почты <a href="mailto:info@oilcase.ru">info@oilcase.ru.</a> с пометкой «Отзыв согласия на обработку персональных данных».',
                ]
            },
            {
                paragraph: '<h3>Заключительные положения</h3>',
                text: [
                    'Пользователь может получить любые разъяснения по интересующим вопросам, касающимся обработки его персональных данных, направив Оператору письмо на адрес электронной почты <a href="mailto:info@oilcase.ru">info@oilcase.ru.</a>',
                    'В данном документе будут отражены любые изменения политики обработки персональных данных Оператором. Политика действует бессрочно до замены ее новой версией. Актуальная версия Политики в свободном доступе расположена в сети Интернет по адресу oilcase.ru/privacy. В случае существенных изменений Пользователю может быть выслана информация на указанный им адрес электронной почты.',
                ]
            },
        ]

        onMounted(() => {
            onScrollAnimate();
        })

        return {
            text_content
        }
    }
})
