
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        frame_color: {
            type: String,
            default: '#F9AD4E'
        },
        info_title: {
            type: String,
            default: 'title'
        },
        info_text: {
            type: String,
            default: 'text'
        }
    }
})
