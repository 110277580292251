import { createApp } from 'vue';
import App from './App.vue';
import { createRouter, createWebHistory } from 'vue-router';
import './components/index.sass';
import store from './store/store'
import axios from 'axios'
import onScrollAnimate from './unilities/scrollAnimate';
import VueCookies from 'vue-cookies';
import VWave from 'v-wave';
import VMask  from '@ssibrahimbas/v-mask';
import vClickOutside from "click-outside-vue3";

import routes from './router';

//компоненты
import headerCmp from './components/base/header/header-cmp.vue';
import btnCmp from './components/ui-components/btn-cmp/btn-cmp.vue';
import infoBlockCmp from './components/ui-components/info-block-cmp/info-block-cmp.vue';
import inputCmp from './components/ui-components/input-cmp/input-cmp.vue';
import footerCmp from './components/base/footer/footer-cmp.vue';
import blockList from './components/main-page/block-list/block-list.vue';
import blockCard from './components/main-page/block-card/block-card.vue';
import blockDemo from './components/main-page/block-demo/block-demo.vue';
import blockClients from './components/main-page/block-clients/block-clients.vue';
import blockSponsor from './components/main-page/block-sponsor/block-sponsor.vue'
import menubarCmp from './components/ui-components/menubar-cmp/menubar-cmp.vue';

const router = createRouter({
    history: createWebHistory(),
    scrollBehavior(to, from, savedPosition) {
        if (to.hash) {
          return {
            selector: to.hash,
          };
        } else if (savedPosition) {
          return savedPosition;
        } else {
          return { top: 0 };
        }
      },
    routes,
});

const app = createApp(App);

// axios.defaults.baseURL = 'http://192.168.19.204:80'
// axios.defaults.baseURL = 'http://192.168.19.194:80'
axios.defaults.baseURL = 'https://champ2024.oilcase.ru'
// axios.defaults.headers.post['Content-Type'] = 'multipart/form-data';

app.component('header-cmp', headerCmp)
app.component('btn-cmp', btnCmp)
app.component('info-block-cmp', infoBlockCmp)
app.component('input-cmp', inputCmp)
app.component('footer-cmp', footerCmp)

app.component('block-list', blockList)
app.component('block-card', blockCard)
app.component('block-demo', blockDemo)
app.component('block-client', blockClients)
app.component('block-sponsor', blockSponsor)
app.component('menubar-cmp', menubarCmp)

app.directive('animation', {
    // Когда привязанный элемент вставлен в DOM...
    mounted(el, binding) {
        el.dataset.animate = false;
        if (binding.arg) {
            el.dataset.animateDirection = binding.arg;
        }
        if (binding.modifiers) {
            Object.keys(binding.modifiers).forEach((item) => {
                el.dataset[item] = binding.modifiers[item];
            });
        }
    },
});

app.directive('v-mask', VMask);

router.afterEach(() => {
    setTimeout(() => {
        onScrollAnimate();
        window.addEventListener('scroll', onScrollAnimate);
    }, 1000);
});

router.beforeEach(() => {
    window.removeEventListener('scroll', onScrollAnimate);
});

app.use(store).use(router).use(VueCookies).use(vClickOutside).use(VMask as any).use(VWave as any).mount('#app');
