import main_page from "./pages/main_page.vue"
import championshipPage from "./pages/championship-page/championship-page.vue"
import privacyPage from "./pages/privacy-page/privacy-page.vue"

const routes = [
    {
        path: '/',
        name: 'main',
        component: main_page
    },
    {
        path: '/championship',
        name: 'championship',
        component: championshipPage
    },
    {
        path: '/privacy',
        name: 'privacy',
        component: privacyPage
    },
]

export default routes