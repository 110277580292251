import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass, resolveDirective as _resolveDirective, openBlock as _openBlock, createElementBlock as _createElementBlock, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = { class: "oilcase-btn__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _directive_wave = _resolveDirective("wave")!

  return (_openBlock(), _createElementBlock("div", null, [
    _withDirectives((_openBlock(), _createElementBlock("button", {
      class: _normalizeClass(["oilcase-btn", [
                _ctx.btn_type === 'primary' ? '_primary' : '_secondary', 
                _ctx.btn_disabled ? '_disabled' : ''
            ]]),
      onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.redirectToLink && _ctx.redirectToLink(...args)))
    }, [
      _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.btn_text), 1),
      _renderSlot(_ctx.$slots, "default")
    ], 2)), [
      [_directive_wave, { color: '#03AEE2' }]
    ])
  ]))
}